/*!
 * =============================================================
 * dropify v0.2.2 - Override your input files with style.
 * https://github.com/JeremyFagis/dropify
 *
 * (c) 2017 - Jeremy FAGIS <jeremy@fagis.fr> (http://fagis.fr)
 * =============================================================
 */

 @charset "UTF-8";

 @font-face {
   font-family: "dropify";
   src: url("../plugins/fileuploads/fonts/dropify.eot");
   src: url("../plugins/fileuploads/fonts/dropify.eot#iefix") format("embedded-opentype"), url("../plugins/fileuploads/fonts/dropify.woff") format("woff"), url("../plugins/fileuploads/fonts/dropify.ttf") format("truetype"), url("../plugins/fileuploads/fonts/dropify.svg#dropify") format("svg");
   font-weight: normal;
   font-style: normal;
 }
 
 [class^="dropify-font-"]:before, [class*=" dropify-font-"]:before, .dropify-font:before, .dropify-wrapper .dropify-message span.file-icon:before {
   font-family: "dropify";
   font-style: normal;
   font-weight: normal;
   speak: none;
   display: inline-block;
   text-decoration: inherit;
   width: 1em;
   margin-left: 0.2em;
   margin-right: 0.2em;
   text-align: center;
   font-variant: normal;
   text-transform: none;
   line-height: 1em;
 }
 
 .dropify-font-upload:before, .dropify-wrapper .dropify-message span.file-icon:before {
   content: "\e800";
 }
 
 .dropify-font-file:before {
   content: "\e801";
 }
 
 .dropify-wrapper {
   display: block;
   position: relative;
   cursor: pointer;
   overflow: hidden;
   width: 100%;
   max-width: 100%;
   height: 200px !important;
   padding: 5px 10px;
   border-radius:5px;
   font-size: 14px;
   line-height: 22px;
   color: #000;
   background-color: #fff;
   background-image: none;
   text-align: center;
   border: 1px solid #f0f0f4;
   -webkit-transition: border-color 0.15s linear;
   transition: border-color 0.15s linear;
 
   &:hover {
     background-size: 30px 30px;
     background-image: -webkit-linear-gradient(135deg, #f6f6f6 25%, transparent 25%, transparent 50%, #f6f6f6 50%, #f6f6f6 75%, transparent 75%, transparent);
     background-image: linear-gradient(-45deg, #f6f6f6 25%, transparent 25%, transparent 50%, #f6f6f6 50%, #f6f6f6 75%, transparent 75%, transparent);
     -webkit-animation: stripes 2s linear infinite;
     animation: stripes 2s linear infinite;
   }
 
   &.has-preview .dropify-clear {
     display: block;
   }
 
   &.has-error {
     border-color: #f34141;
 
     .dropify-message .dropify-error {
       display: block;
     }
 
     &:hover .dropify-errors-container {
       visibility: visible;
       opacity: 1;
       -webkit-transition-delay: 0s;
       transition-delay: 0s;
     }
   }
 
   &.disabled {
     input {
       cursor: not-allowed;
     }
 
     &:hover {
       background-image: none;
       -webkit-animation: none;
       animation: none;
     }
 
     .dropify-message {
       opacity: 0.5;
       text-decoration: line-through;
     }
 
     .dropify-infos-message {
       display: none;
     }
   }
 
   input {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     height: 100%;
     width: 100%;
     opacity: 0;
     cursor: pointer;
     z-index: 5;
   }
 
   .dropify-message {
     position: relative;
     top: 50%;
     -webkit-transform: translateY(-50%);
     transform: translateY(-50%);
 
     span.file-icon {
       font-size: 50px;
       color: #68798b;
     }
 
     p {
       margin: 5px 0 0 0;
       font-size: 14px;
 
       &.dropify-error {
         color: #f34141;
         font-weight: bold;
         display: none;
       }
     }
   }
 
   .dropify-clear {
     display: none;
     position: absolute;
     opacity: 0;
     z-index: 7;
     top: 10px;
     right: 10px;
     background: none;
     border: 2px solid #fff;
     text-transform: uppercase;
     font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial";
     font-size: 11px;
     padding: 4px 8px;
     font-weight: bold;
     color: #fff;
     -webkit-transition: all 0.15s linear;
     transition: all 0.15s linear;
 
     &:hover {
       background: rgba(255, 255, 255, 0.2);
     }
   }
 
   .dropify-preview {
     display: none;
     position: absolute;
     z-index: 1;
     background-color: #fff;
     padding: 5px;
     width: 100%;
     height: 100%;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     overflow: hidden;
     text-align: center;
 
     .dropify-render {
       img {
         top: 50%;
         -webkit-transform: translate(0, -50%);
         transform: translate(0, -50%);
         position: relative;
         max-width: 100%;
         max-height: 100%;
         background-color: #fff;
         -webkit-transition: border-color 0.15s linear;
         transition: border-color 0.15s linear;
       }
 
       i {
         font-size: 70px;
         top: 50%;
         left: 50%;
         -webkit-transform: translate(-50%, -50%);
         transform: translate(-50%, -50%);
         position: absolute;
         color: #777;
       }
 
       .dropify-extension {
         position: absolute;
         top: 50%;
         left: 50%;
         -webkit-transform: translate(-50%, -50%);
         transform: translate(-50%, -50%);
         margin-top: 10px;
         text-transform: uppercase;
         font-weight: 900;
         letter-spacing: -0.03em;
         font-size: 13px;
         width: 42px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
       }
     }
 
     .dropify-infos {
       position: absolute;
       left: 0;
       top: 0;
       right: 0;
       bottom: 0;
       z-index: 3;
       background: rgba(0, 0, 0, 0.7);
       opacity: 0;
       -webkit-transition: opacity 0.15s linear;
       transition: opacity 0.15s linear;
 
       .dropify-infos-inner {
         position: absolute;
         top: 50%;
         -webkit-transform: translate(0, -40%);
         transform: translate(0, -40%);
         -webkit-backface-visibility: hidden;
         backface-visibility: hidden;
         width: 100%;
         padding: 0 20px;
         -webkit-transition: all 0.2s ease;
         transition: all 0.2s ease;
 
         p {
           padding: 0;
           margin: 0;
           position: relative;
           width: 100%;
           white-space: nowrap;
           overflow: hidden;
           text-overflow: ellipsis;
           color: #fff;
           text-align: center;
           line-height: 25px;
           font-weight: bold;
 
           &.dropify-infos-message {
             margin-top: 15px;
             padding-top: 15px;
             font-size: 12px;
             position: relative;
             opacity: 0.5;
 
             &::before {
               content: "";
               position: absolute;
               top: 0;
               left: 50%;
               -webkit-transform: translate(-50%, 0);
               transform: translate(-50%, 0);
               background: #fff;
               width: 30px;
               height: 2px;
             }
           }
         }
       }
     }
   }
 
   &:hover {
     .dropify-clear {
       opacity: 1;
     }
 
     .dropify-preview .dropify-infos {
       opacity: 1;
 
       .dropify-infos-inner {
         margin-top: -5px;
       }
     }
   }
 
   &.touch-fallback {
     height: auto !important;
 
     &:hover {
       background-image: none;
       -webkit-animation: none;
       animation: none;
     }
 
     .dropify-preview {
       position: relative;
       padding: 0;
 
       .dropify-render {
         display: block;
         position: relative;
 
         .dropify-font-file {
           position: relative;
           -webkit-transform: translate(0, 0);
           transform: translate(0, 0);
           top: 0;
           left: 0;
 
           &::before {
             margin-top: 30px;
             margin-bottom: 30px;
           }
         }
 
         img {
           position: relative;
           -webkit-transform: translate(0, 0);
           transform: translate(0, 0);
         }
       }
 
       .dropify-infos {
         position: relative;
         opacity: 1;
         background: transparent;
 
         .dropify-infos-inner {
           position: relative;
           top: 0;
           -webkit-transform: translate(0, 0);
           transform: translate(0, 0);
           padding: 5px 90px 5px 0;
 
           p {
             padding: 0;
             margin: 0;
             position: relative;
             width: 100%;
             white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;
             color: #777;
             text-align: left;
             line-height: 25px;
 
             &.dropify-filename {
               font-weight: bold;
             }
 
             &.dropify-infos-message {
               margin-top: 0;
               padding-top: 0;
               font-size: 11px;
               position: relative;
               opacity: 1;
 
               &::before {
                 display: none;
               }
             }
           }
         }
       }
     }
 
     .dropify-message {
       -webkit-transform: translate(0, 0);
       transform: translate(0, 0);
       padding: 40px 0;
     }
 
     .dropify-clear {
       top: auto;
       bottom: 23px;
       opacity: 1;
       border-color: rgba(119, 119, 119, 0.7);
       color: #777;
     }
 
     &.has-preview .dropify-message {
       display: none;
     }
 
     &:hover .dropify-preview .dropify-infos .dropify-infos-inner {
       margin-top: 0;
     }
   }
 
   .dropify-loader {
     position: absolute;
     top: 15px;
     right: 15px;
     display: none;
     z-index: 9;
 
     &::after {
       display: block;
       position: relative;
       width: 20px;
       height: 20px;
       -webkit-animation: rotate 0.6s linear infinite;
       animation: rotate 0.6s linear infinite;
       border-radius: 100%;
       border-top: 1px solid #ccc;
       border-bottom: 1px solid #777;
       border-left: 1px solid #ccc;
       border-right: 1px solid #777;
       content: "";
     }
   }
 
   .dropify-errors-container {
     position: absolute;
     left: 0;
     top: 0;
     right: 0;
     bottom: 0;
     z-index: 3;
     background: rgba(243, 65, 65, 0.8);
     text-align: left;
     visibility: hidden;
     opacity: 0;
     -webkit-transition: visibility 0s linear 0.15s, opacity 0.15s linear;
     transition: visibility 0s linear 0.15s, opacity 0.15s linear;
 
     ul {
       padding: 10px 20px;
       margin: 0;
       position: absolute;
       left: 0;
       top: 50%;
       -webkit-transform: translateY(-50%);
       transform: translateY(-50%);
 
       li {
         margin-left: 20px;
         color: #fff;
         font-weight: bold;
       }
     }
 
     &.visible {
       visibility: visible;
       opacity: 1;
       -webkit-transition-delay: 0s;
       transition-delay: 0s;
     }
   }
 
   ~ .dropify-errors-container ul {
     padding: 0;
     margin: 15px 0;
 
     li {
       margin-left: 20px;
       color: #f34141;
       font-weight: bold;
     }
   }
 }
 
 @-webkit-keyframes stripes {
   from {
     background-position: 0 0;
   }
 
   to {
     background-position: 60px 30px;
   }
 }
 
 @keyframes stripes {
   from {
     background-position: 0 0;
   }
 
   to {
     background-position: 60px 30px;
   }
 }
 
 @-webkit-keyframes rotate {
   0% {
     -webkit-transform: rotateZ(-360deg);
     transform: rotateZ(-360deg);
   }
 
   100% {
     -webkit-transform: rotateZ(0deg);
     transform: rotateZ(0deg);
   }
 }
 
 @keyframes rotate {
   0% {
     -webkit-transform: rotateZ(-360deg);
     transform: rotateZ(-360deg);
   }
 
   100% {
     -webkit-transform: rotateZ(0deg);
     transform: rotateZ(0deg);
   }
 }